<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="empid"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="uid"
      :show-expand="expandflag"
      class="elevation-1"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD USER</v-btn>
              <!-- <v-btn  icon  v-bind="attrs" v-on="on" :to="backmenu"><v-icon class="blue">arrow_back</v-icon></v-btn>-->
            </template>

            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                    <v-row>
                      <!-- <v-col v-if="editflag" cols="12" xs="6" sm="6" md="2" lg="2">
                        <div>Employee ID</div>
                        <div>{{ editedItem.empid }}</div>
                      </v-col> -->
                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field v-model="editedItem.empid" :counter="6" :rules="empidRules" label="Employee ID">
                        </v-text-field>
                      </v-col>
                      <v-col v-if="editflag" cols="12" xs="6" sm="6" md="4" lg="4">
                        <div>E-mail</div>
                        <div>{{ editedItem.email }}</div>
                      </v-col>
                      <v-col v-else cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-text-field v-model="editedItem.email" :rules="emailRules" label="E-mail"> </v-text-field>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-text-field v-model="editedItem.name" :rules="nameRules" label="Name" required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-select
                          v-model="editedItem.status"
                          :rules="nameRules"
                          :items="allStatus"
                          label="Status"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-combobox
                          v-model="editedItem.mgrName"
                          :rules="nameRules"
                          :items="mgrNames"
                          label="Manager Name"
                          required
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-combobox
                          v-model="editedItem.mgremail"
                          :rules="emailRules"
                          :items="mgremails"
                          label="Manager Email"
                          required
                        >
                        </v-combobox>
                      </v-col>

                      <v-col v-if="editflag" cols="12" xs="6" sm="6" md="4" lg="4">
                        <div>Role(s)</div>
                        <div>{{ editedItem.role }}</div>
                      </v-col>
                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-select
                          v-model="newroles"
                          multiple
                          :items="allRole"
                          :rules="rolesRules"
                          label="New Role(s)"
                          required
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" :disabled="!valid" text @click="update">Save</v-btn>
                  <v-btn color="red darken-1" v-if="!spinner" :disabled="!valid" text @click="updateemp">Update emp number</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="6" sm="6" md="3" lg="3">
              <h4>Manager</h4>
              {{ item.mgrName }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="3" lg="3">
              <h4>Manager Email</h4>
              {{ item.mgremail }}
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3" lg="3">
              <h4>Created On</h4>
              {{ convertDate(item.createdOn) }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="3" lg="3">
              <h4>Modified On</h4>
              {{ convertDate(item.modifiedOn) }}
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="editrole" small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon v-if="editrole" small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Users</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
//import "firebase/auth";
import { usersRef, emailRef, userconfig,empRef } from "@/fb"
//import { emailMixin } from "../mixins/emailMixin.js"
import { formMixin } from "@/mixins/formMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import { checkRoles } from "@/mixins/checkRoles.js"
import { newUserMixin } from "@/mixins/newUserMixin.js"
import { updateUserMixin } from "@/mixins/updateUserMixin.js"
import { getUserMixin } from "@/mixins/getUserMixin.js"
import { deleteemailMixin } from "@/mixins/deleteemailMixin.js"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import {mapState} from "vuex"
import Spinner from "@/components/common/spinner.vue"
//import AppBar from "../components/AppBar.vue"

export default {
  name: "Regitser",
  mixins: [formMixin, convertAll, newUserMixin, updateUserMixin, checkRoles, getUserMixin, deleteemailMixin,collectionsOperation],

  components: { Snackbar, Alertmsg, Spinner },
  //props:['roles'],
  /* components: {
    AppBar,
    
    },*/

  data: () => ({
    valid: true,
    show1: false,
    dialog: false,

    singleExpand: false,
    expanded: [],
    expandflag: true,

    password: "",
    name: "",
    mgrNames: [],
    mgremails: [],
    empids: [],
    users: [],
    mgrName: "",
    mgremail: "",

    empid: null,
    empidRules: [
      (v) => !!v || "empid is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    rolesRules: [(v) => !!v || "Role(s) is required"],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 4) || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets and Spaces allowed",
    ],
    email: null,
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match",
    },
    select: null,

    allRole: [],
    allStatus: ["newuser", "active", "deactive"],
    headers: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "empid",
      },
      { text: "Email", value: "email", sortable: true },
      { text: "Name", value: "name", sortable: true },
      // { text: 'Manager Name', value: 'mgrName' , sortable: true,},
      //   {text: 'Manager Email',value:'mgremail',sortable: true,},
      { text: "Role(s)", value: "role" },
      { text: "Status", value: "status", sortable: true },
      { text: "createdOn", value: "createdOn", align: " d-none" },
      { text: "modifiedOn", value: "modifiedOn", align: " d-none" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    status: null,
    checkbox: false,
    lazy: false,
    error: "",
    notloaded: true,

    // role:[],
    newroles: [],
    superadmin: true,
    uid: null, // stores the the unique id for the record
    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
    newUser: {},
    viewflag: false,

    editedIndex: -1,
    editedItem: {
      empid: "",
      email: "",
      name: "",
      mgrName: "",
      mgremail: "",
      role: [],
      status: "",
      domain: "",
      createdOn: "",
      modifiedOn: "",
      userconfig: {},
    },
    defaultItem: {},

    search: "",

    editflag: false,
    alertflag: false,
    alertmsg: null,
    dialogclose: false,
    snackbar: false,
    snackbartext: "",
    roles: "",
    editrole: false,
    spinner: false,
    oldEmpid:'',
    officialDetails:{
        empid:'',
        email:'',
        name:'',
        mgrname:'',
        empDOJ:'',
      },
  }), // end of data
  async created() {
    this.roles = await this.$store.state.userProfile

    if (this.roles.role.superadmin || this.roles.role.readuser) {
      this.domain = this.roles.domain
      this.getEmplist()
      if (this.roles.role.superadmin) this.editrole = true
      this.defaultItem = Object.assign({}, this.editedItem)
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: this.roles.menufrom })
    }
  },

   mounted () {
       
        usersRef.where("domain", "==", this.currentDomain)
	.onSnapshot( (snapshot) => {
        snapshot.docChanges().forEach((change) => {
         
            if(!this.notloaded)
             this.updaterealtimedata(this.users,change)
        
          })// end of for each
        })// end of snapshot
    }, // end of mounted

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add User" : "Edit User"
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    clearalert() {
      this.alertflag = false
      if (this.dialogclose) this.close()
    },
    async getEmplist() {
      ;(this.users = []), (this.notloaded = true)
      console.log("Register domain name is ", this.domain)
      var queryresult = await usersRef.where("domain", "==", this.domain).get()
      for (var i = 0; i < queryresult.docs.length; i++) {
        this.mgremails.push(queryresult.docs[i].data().email)
        this.mgrNames.push(queryresult.docs[i].data().name)
        this.empids.push(queryresult.docs[i].data().empid)

        if (!queryresult.docs[i].data().createdOn)
          queryresult.docs[i].data().createdOn = new Date().toISOString().substr(0, 10)
        var tmpuserconfig = queryresult.docs[i].data().userconfig
        if (!tmpuserconfig) tmpuserconfig = userconfig

        this.users.push({
          ...queryresult.docs[i].data(),
          uid: queryresult.docs[i].id,
          userconfig: tmpuserconfig,
          // role:tmproles
        })
      } // end of for loop
      this.notloaded = false

      queryresult = await emailRef.where("domain", "==", this.domain).get()
      var tmpallroles = queryresult.docs[0].data().roles
      for (i = 0; i < tmpallroles.length; i++) {
        this.allRole.push(tmpallroles[i])
      }
    }, // end of getEmplist

    async updateuserclaims(email, roles) {
      this.newUser = {}
      var rolesobject = Object.assign.apply(
        null,
        roles.map((x) => ({ [x]: true }))
      ) // custom claims
      this.newUser.email = email
      this.newUser.rolesobject = rolesobject
      let Ret = await this.updateUser(this.newUser)
      console.log("From updateUser", email, Ret)
    },
    async getuserroles(email) {
      this.newUser = {}
      this.newUser.email = email
      var tmproles = []
      var user = await this.getUser(this.newUser)
      // console.log("getuserroles",user.data);
      console.log("getuserroles customClaims", email, user.data.customClaims)
      if (user.data.customClaims.superadmin) tmproles.push("superadmin")
      if (user.data.customClaims.sysadmin) tmproles.push("sysadmin")
      if (user.data.customClaims.hradmin) tmproles.push("hradmin")
      if (user.data.customClaims.employee) tmproles.push("employee")
      if (user.data.customClaims.recruitment) tmproles.push("recruitment")
      if (user.data.customClaims.readuser) tmproles.push("readuser")
      return tmproles
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editflag = true
      this.dialog = true
      this.dialogclose = false
      this.oldEmpid= this.editedItem["empid"]
      console.log("inside edititem-item",item["empid"])
      console.log("inside edititem-editedItem", this.editedItem["empid"])
    },

    async deleteItem(item) {
      var index = this.users.indexOf(item)
      const deletemsg = "Are you sure you want to delete this user?" + item.empid
      const option = await this.deleteFromFb(deletemsg)
      console.log("inside deleteItem", option, index, item.uid)

      if (option) {
        try {
          var deleteUser = {}
          deleteUser.email = item.email
          deleteUser.uid = item.uid
          var Ret = await this.deleteemail(deleteUser)
          console.log("Ret form deleteuser", Ret)
          this.snackbar = true
          if (Ret.status == true) {
            //usersRef.doc(item.uid).delete();
            console.log("inside Ret status true")
            this.users.splice(index, 1)
            index = this.empids.indexOf(item.empid)
            if (index > -1) this.empids.splice(index, 1)

            index = this.mgremails.indexOf(item.email)
            if (index > -1) this.mgremails.splice(index, 1)

            this.snackbartext = "User has been deleted"
          } else {
            console.log("inside Ret status false")
            this.snackbartext = Ret.error
          }
        } catch (error) {
          console.log("Error while delete :", error)
          this.alertflag = true
          this.alertmsg = "Error while delete :" + error
        }
      }
    },

    close() {
      this.dialog = false
      this.editflag = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    reset() {
      this.$refs.form.reset()
    },
    async updateemp() {
      //alert("inside the update function")
    
      this.spinner = true
      this.viewflag = false
      this.editflag = false
     

      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
        console.log("inside empsave item ", this.editedItem)

        try {
          if (!this.editedItem.createdOn) this.editedItem.createdOn = new Date().toISOString().slice(0, 10)

          this.editedItem.modifiedOn = new Date().toISOString().slice(0, 10)

          /*  this.newUser.email=this.editedItem.email;
         this.newUser.empid=this.editedItem.empid;
         this.newUser.status=this.editedItem.status;
         this.newUser.newroles=this.editedItem.role;
         this.newUser.name=this.editedItem.name;
         this.newUser.mgrName=this.editedItem.mgrName;
         this.newUser.mgremail=this.editedItem.mgremail;
         this.newUser.domain=this.editedItem.domain;
         this.newUser.rolesobject=rolesobject;*/
       
          
         console.log("emp Data is before",this.domain, this.oldEmpid)
          var queryresult = await empRef.where("domain", "==", this.domain)
          .where("empid","==",this.oldEmpid).get()
          console.log("queryresult.docs.length",queryresult.docs.length)
          if(queryresult.docs.length==1){
            var data = queryresult.docs[0].data()
            this.officialDetails=queryresult.docs[0].data().officialDetails
            this.officialDetails.empid=this.editedItem.empid
            //uid: queryresult.docs[i].id
            console.log("emp Data is",queryresult.docs[0].id,data.uid,this.editedItem.empid,this.officialDetails)
            empRef.doc(queryresult.docs[0].id).update({
              empid:this.editedItem.empid,
              officialDetails:this.officialDetails
            })
          }
          usersRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
        
          //alert("updated successfully")
          this.snackbar = true
          this.snackbartext = "User updated successfully"
          this.close()
          this.dialogclose = true
          this.valid = true
          this.newroles = []
        } catch (error) {
          console.log("Error while update :", error)
          //alert("updated successfully")
          this.alertflag = true
          this.alertmsg = "Error while update :" + error
          this.spinner = false
        }
      } else {
        console.log("inside update add new users")
        this.Register()
      }
      //this.initialize();
      // this.close()
    },
    async update() {
      //alert("inside the update function")
      var newroleupdate = false
      this.spinner = true
      this.viewflag = false
      this.editflag = false
      if (this.newroles.length >= 1) {
        newroleupdate = true
        console.log("inside newroles assignment", this.newroles)
        this.editedItem.role = JSON.parse(JSON.stringify(this.newroles))
      }

      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
        console.log("inside save item ", this.editedItem)

        try {
          if (!this.editedItem.createdOn) this.editedItem.createdOn = new Date().toISOString().slice(0, 10)
               this.editedItem.modifiedOn = new Date().toISOString().slice(0, 10)

          if (newroleupdate == true) {
            this.newUser = {}
            var rolesobject = Object.assign.apply(
              null,
              this.editedItem.role.map((x) => ({ [x]: true }))
            ) // custom claims
            this.newUser = Object.assign({}, this.editedItem)
            this.newUser.rolesobject = rolesobject
            let Ret = await this.updateUser(this.newUser)
            console.log("From updateUser", Ret)
          }
          usersRef.doc(this.editedItem.uid).set({
            /*  status:this.editedItem.status,
            role:this.editedItem.role,
            empid:this.editedItem.empid,
            email:this.editedItem.email,
            name:this.editedItem.name,
            mgrName:this.editedItem.mgrName,
            mgremail:this.editedItem.mgremail,
            domain:this.editedItem.domain,
            createdOn:this.editedItem.createdOn,
           // modifiedOn:new Date().toISOString().slice(0,10),
            userconfig:this.editedItem.userconfig,*/
            ...this.editedItem,
          })
          //alert("updated successfully")
          this.snackbar = true
          this.snackbartext = "User updated successfully"
          this.close()
          this.dialogclose = true
          this.valid = true
          this.newroles = []
        } catch (error) {
          console.log("Error while update :", error)
          //alert("updated successfully")
          this.alertflag = true
          this.alertmsg = "Error while update :" + error
          this.spinner = false
        }
      } else {
        console.log("inside update add new users")
        this.Register()
      }
      //this.initialize();
      // this.close()
    },

    resetValidation() {
      //this.$refs.form.reset()
      this.empid = ""
      this.email = ""
      this.status = ""
      this.role = ""
      this.name = ""
      this.newroles = ""
    },

    async Register() {
      console.log("inside Register edititem values", this.editedItem)
      var objIndexempid = this.empids.indexOf(this.editedItem.empid)
      var objIndexemail = this.mgremails.indexOf(this.editedItem.email)
      console.log("isnide email and empid checking", objIndexempid, objIndexemail)

      if (objIndexempid > -1 || objIndexemail > -1) {
        this.alertflag = true
        this.spinner = false
        if (objIndexempid > -1) this.alertmsg = "EMP ID is already exisits"
        else this.alertmsg = "Email ID is already exisits"
      } else {
        this.valid = false
        this.editedItem.createdOn = new Date().toISOString().slice(0, 10)
        this.editedItem.domain = this.domain
        this.newUser = {}
        var rolesobject = Object.assign.apply(
          null,
          this.editedItem.role.map((x) => ({ [x]: true }))
        ) // custom claims role object;
        this.newUser = Object.assign({}, this.editedItem)
        this.newUser.rolesobject = rolesobject

        let Ret = await this.addUser(this.newUser)
        console.log("From newUser creation", Ret)

        if (Ret.status == true) {
          this.mgremails.push(this.editedItem.email)
          this.empids.push(this.editedItem.empid)
          this.snackbar = true
          this.snackbartext = "New user created successfully"
          this.dialogclose = true
          this.close()
          this.valid = true
          this.newroles = []
          this.users.push({
            ...this.editedItem,
            uid: Ret.uid,
            userconfig: Ret.userconfig,
          })

          // this.getEmplist()
          // this.mailfun();
          this.resetValidation()
        } else {
          this.alertflag = true
          this.alertmsg = "Error while crate the new user please check with admin" + Ret
          this.spinner = false
        }

        console.log("inside Register end of else(user creations")
      } // end of required fileds else
    },
  },
}
</script>
