import axios from "axios"
import { getUserRolesurl } from "../fb"

export const getUserMixin = {
  methods: {
    async getUser(body) {
      console.log("inside getUser",getUserRolesurl, body)
      try {
        var userdetails = await axios.post(getUserRolesurl, body)
        //  console.log("getUserRoles:user role fetched sucessfully",userdetails)
        return userdetails
      } catch (err) {
        console.log(err)
        return err
      }
    }, // end of addUser method
  },
}
