export const checkRoles = {
  methods: {
    checkrole(roles, requiredrole) {
      if (roles) {
        console.log("requiredrole", roles, requiredrole)
        /*   if(this.roles.role.includes(requiredrole))
                {
                    return "permituser"
                }
                else if(this.roles.role.includes("readuser"))
                { 
                    return "readuser"
                }
                else
                { 
                    return "notpermitted"
                }*/
        return "permituser"
      } else {
        return "failure"
      }
    }, // end of checkrole
  }, // end of methods
}
