import axios from "axios"
import { updateUserRolesurl } from "../fb"

export const updateUserMixin = {
  methods: {
    async updateUser(body) {
      console.log("inside updateUser", body)
      try {
        await axios.post(updateUserRolesurl, body)
        console.log("updateUser:user role updated sucessfully")
        return true
      } catch (err) {
        console.log(err)
        return err
      }
    }, // end of addUser method
  },
}
