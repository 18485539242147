import axios from "axios"
import { usersRef, userconfig, deleteemailurl } from "../fb"

//raja@venzoconsulting.com
export const deleteemailMixin = {
  data: function () {
    return {
      retobj: { uid: "", status: "", userconfig: "", error: "" },
    }
  },

  methods: {
    async deleteemail(body) {
      console.log("inside deleteemail", body)

      var Ret = await this.deletecallpost(body)
      console.log("addUser Ret ", Ret)

      console.log("addUser retobj ", this.retobj)
      return this.retobj
    }, // end of addUser method
    async deletecallpost(body) {
      try {
        console.log("inside callpost delete", deleteemailurl, body)
        await axios.post(deleteemailurl, body)

        await this.deleteemailData(body)
        console.log("deletecallpost retobj ", this.retobj)
        return
      } catch (err) {
        console.log(err)
        this.retobj.status = false
        return
      }
    }, // end of callpost
    async deleteemailData(body) {
      try {
        var retobj = {}
        console.log("inside deleteemail", body)

        await usersRef.doc(body.uid).delete()

        this.retobj.status = true
        this.retobj.uid = body.uid
        this.retobj.userconfig = userconfig
        console.log("deleteemailData retobj ", retobj)
        return

        /* await usersRef.where("email","==",body.oldemail).get().then(docRef => {
            console.log("inside update uid",docRef.id)
            usersRef.doc(docRef.id).update({email:body.email});
            this.mailfun(body);
            this.retobj.status=true;
            this.retobj.uid=docRef.id;
            this.retobj.userconfig=userconfig;
            console.log("updateemailData retobj ",retobj)
            return ;

          });

        await usersRef.where('email','==',body.oldemail).update({
          email:body.email
          }).then(docRef => {
            this.mailfun(body);
            this.retobj.status=true;
            this.retobj.uid=docRef.id;
            this.retobj.userconfig=userconfig;
            console.log("updateemailData retobj ",retobj)
            return ;

          });*/
      } catch (err) {
        this.retobj.status = false
        this.retobj.error = err
        return
      }
    }, // end of addTableData

    /* async addUser(body) {
      console.log("inside addUser")
      await axios
        .post("https://us-central1-venzoadmindev.cloudfunctions.net/addUser", body)
        .then((res) => {
          console.log(res)
          try{
            usersRef.add({
            empid:body.empid,
            email:body.email,
            status:body.status,
            role:body.newroles,
            name:body.name,
            mgrName:body.mgrName,
            mgremail:body.mgremail,
            domain:body.domain,
          
            createdOn:new Date().toISOString().substr(0, 10),
            userconfig:body.userconfig,
            }).then(docRef => {
             console.log(docRef)
             return true
            })
          }
          catch (err)
          {
            console.log(err)
            return err
          }

          
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },*/ // end of add user
  },
}
//sendMail
